/**
 * This error type is stored in the cookie by the server
 * See https://github.com/OctopusDeploy/DirectoryServicesAuthenticationProvider/blob/ac03a7444d3233934d76af5d3289180c438cedd7/source/Server/IntegratedAuthentication/IntegratedChallengeCoordinator.cs#L48
 */
interface ServerAuthenticationError {
    ErrorMessage: string;
    Errors: string[];
    DetailLinks: string[];
}

function isServerAuthenticationError(error: object): error is ServerAuthenticationError {
    return "ErrorMessage" in error && "Errors" in error && "DetailLinks" in error;
}

export class AuthenticationError extends Error {
    Errors: string[];
    DetailLinks: string[];

    constructor(message: string, errors?: string[], detailLinks?: string[]) {
        super(message);

        this.Errors = errors ?? [];
        this.DetailLinks = detailLinks ?? [];

        Object.setPrototypeOf(this, AuthenticationError.prototype);
    }

    static createFromCookie(cookie: string): AuthenticationError | null {
        const errors = cookie
            .split(";")
            .filter((c) => {
                return c.trim().indexOf("Octopus-Auth-Error") === 0;
            })
            .map((c) => {
                return decodeURIComponent(c.trim().substring("Octopus-Auth-Error=".length));
            });

        if (errors.length > 0 && errors[0]) {
            const errorObj: ServerAuthenticationError = JSON.parse(errors[0]);

            if (isServerAuthenticationError(errorObj)) {
                return new AuthenticationError(errorObj.ErrorMessage, errorObj.Errors, errorObj.DetailLinks);
            }
        }

        return null;
    }
}
